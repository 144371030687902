import { createSlice, createSelector } from "@reduxjs/toolkit";
import { enqueueSnackbar } from "./notifications";
import NotificationRepo from "../repositories/NotificationRepo";

const notificationModuleSlice = createSlice({
  name: "notificationModule",
  initialState: {
    loading: false,
    responding: false,
    error: null,
    data: [],
    single: {},
    singleLoading: false,
  },
  reducers: {
    getAll: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    getAllSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.data = action.payload.data;
    },
    getAllFailed: (state, action) => {
      state.loading = false;
      state.error = null;
    },
    notificationCheck: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    notificationCheckSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.data = action.payload.data;
    },
    notificationCheckFailed: (state, action) => {
      state.loading = false;
      state.error = null;
    },
  },
});

const { actions, reducer } = notificationModuleSlice;

const getnotificationModule = (state) => state.notificationModule.data;
const getIsLoading = (state) => state.notificationModule.loading;

const selectors = {
  data: createSelector(getnotificationModule, (data) => data),
  isLoading: createSelector(getIsLoading, (data) => data),
};

const getAll = (query) => async (dispatch) => {
  dispatch(actions.getAll());
  try {
    const response = await NotificationRepo.getAll(query);
    if (response.success || response.status === 200) {
      dispatch(actions.getAllSuccess(response));
      return {
        data: response,
        page: response.current_page - 1,
        totalCount: response.total,
      };
    } else {
      throw Error(
        response.errMgs ? response.errMgs : "Get All Notification Failed"
      );
    }
  } catch (error) {
    
    dispatch(actions.getAllFailed(error.message));
    dispatch(
      enqueueSnackbar({
        message: "Cannot Get All Notification.",
        options: {
          key: new Date().getTime() + Math.random(),
          variant: "error",
        },
      })
    );
    return error;
  }
};

const notificationCheck = (query) => async (dispatch) => {
  dispatch(actions.notificationCheck());
  try {
    const response = await NotificationRepo.notificationCheck(query);
    if (response.success || response.status === 200) {
      dispatch(actions.notificationCheckSuccess(response));
      return {
        data: response,
        page: response.current_page - 1,
        totalCount: response.total,
      };
    } else {
      throw Error(
        response.errMgs ? response.errMgs : "Notification Check Failed"
      );
    }
  } catch (error) {
   
    dispatch(actions.notificationCheckFailed(error.message));
    dispatch(
      enqueueSnackbar({
        message: "Notification Check Failed.",
        options: {
          key: new Date().getTime() + Math.random(),
          variant: "error",
        },
      })
    );
    return error;
  }
};

export default { actions, reducer, selectors, getAll, notificationCheck };
