import querparser from "query-string";
import SuperFetch from "../api/SuperFetch";

class ReviewRepo {
  createReview(values) {
    return SuperFetch(`/review/listing`, {
      method: "POST",
      data: values,
    });
  }

  getAll(id, query) {
    
    return SuperFetch(`/review?listing=${id}?${querparser.stringify(query)}`);
  }
}

export default new ReviewRepo();
