import querparser from "query-string";
import SuperFetch from "../api/SuperFetch";

class NotificationRepo {
  getAll(query) {
    return SuperFetch(`/notification?${querparser.stringify(query)}`);
  }
  notificationCheck(values) {
    return SuperFetch(`/notification/check`, {
      method: "POST",
      data: values,
    });
  }
}

export default new NotificationRepo();
