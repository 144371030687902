import { createTheme } from "@mui/material/styles";
import { red } from "@mui/material/colors";

// Create a theme instance.
const theme = createTheme({
  palette: {
    primary: {
      main: "#E81478",
    },
    secondary: {
      main: "#1A1819",
    },
    error: {
      main: red.A400,
    },
  },

  typography: {
    allVariants: {
      fontFamily: ["Inter", "sans-serif"].join(","),
      textTransform: "none",
      fontSize: 16,
    },
    h1: {
      fontSize: 48,
      color: "var(--color-natural)",
      fontWeight: 600,
    },
    h2: {
      fontSize: 40,
      color: "var(--color-black)",
      fontWeight: 600,
    },
    h3: {
      fontSize: 16,
      color: "var(--color-natural-4)",
      fontWeight: 400,
    },
    h4: {
      fontSize: 16,
      color: "var(--text-primary)",
      fontWeight: 600,
    },
    h5: {
      fontSize: 14,
      color: "var(--text-secondary)",
      fontWeight: 500,
    },
    h6: {
      fontSize: 12,
      color: "var(--text-primary)",
      fontWeight: 500,
    },
    title1: {
      fontSize: 20,
      color: "var(--text-primary)",
      fontWeight: 600,
    },
    subtitle1: {
      fontSize: 14,
      color: "var(--text-primary)",
      fontWeight: 500,
    },
    subtitle2: {
      fontSize: 12,
      color: "var(--text-primary)",
      fontWeight: 400,
    },
    subtitle3: {
      fontSize: 10,
      color: "var(--text-secondary)",
      fontWeight: 500,
    },
    caption: {
      fontSize: 24,
      color: "var(--text-primary)",
      fontWeight: 600,
    },
    body2: {
      fontSize: 16,
      color: "var(--text-secondary)",
      fontWeight: 400,
    },
    body1: {
      fontSize: 18,
      color: "var(--text-primary)",
      fontWeight: 600,
    },
  },
  overrides: {
    MuiButton: {
      root: {
        fontWeight: "bold",
        fontSize: "1rem",
      },
    },
  },
  components: {
    MuiTypography: {
      defaultProps: {
        sx: {
          px: 0,
        },
        variant: "subtitle2",
        textTransform: "none",
      },
    },
    MuiStack: {
      defaultProps: {
        sx: {
          px: 2,
          py: 1,
        },
        spacing: 2,
        direction: "row",
      },
    },
    MuiPaper: {
      defaultProps: {
        elevation: 0,
      },
    },
    MuiLink: {
      defaultProps: {
        sx: {
          color: (theme) => theme.palette.primary.main,
        },
        underline: "none",
      },
    },
    // MuiButton: {
    //   defaultProps: {
    //     disableRipple: true,
    //     disableElevation: true,
    //   },
    // },
    MuiTab: {
      defaultProps: {
        disableRipple: true,
      },
    },
  },
});

export default theme;
