import { createSlice, createSelector } from "@reduxjs/toolkit";
import ConversationRepo from "../repositories/ConversationRepo";
import { enqueueSnackbar } from "./notifications";
const conversationsSlice = createSlice({
  name: "conversations",
  initialState: {
    loading: false,
    responding: false,
    error: null,
    data: [],
    bookingData: [],
    isBookingLoading: false,
  },
  reducers: {
    getAll: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    getAllSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.data = action.payload.data;
    },
    getAllFailed: (state, action) => {
      state.loading = false;
      state.error = null;
    },
    getBookingDetails: (state, action) => {
      state.isBookingLoading = true;
      state.error = null;
    },
    getBookingDetailsSuccess: (state, action) => {
      state.isBookingLoading = false;
      state.error = null;
      state.bookingData = action.payload.data;
    },
    getBookingDetailsFailed: (state, action) => {
      state.isBookingLoading = false;
      state.error = null;
    },

    getSingleconversations: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    getSingleconversationsSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.data = action.payload.data;
    },
    getSingleconversationsFailed: (state, action) => {
      state.loading = false;
      state.error = null;
    },

    createConversation: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    createConversationSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
    },
    createConversationFailed: (state, action) => {
      state.loading = false;
      state.error = null;
    },
  },
});

const { actions, reducer } = conversationsSlice;

const getconversations = (state) => state.conversations.data;
const getIsLoading = (state) => state.conversations.loading;
const getIsBookingLoading = (state) => state.conversations.isBookingLoading;
const getsingleconversations = (state) => state.conversations.single;
const getBookingdata = (state) => state.conversations.bookingData;

const selectors = {
  data: createSelector(getconversations, (data) => data),
  isLoading: createSelector(getIsLoading, (data) => data),
  single: createSelector(getsingleconversations, (data) => data),
  isBookingLoading: createSelector(getIsBookingLoading, (data) => data),
  bookingData: createSelector(getBookingdata, (data) => data),
};

const getAll = (query) => async (dispatch) => {
  dispatch(actions.getAll());
  try {
    const response = await ConversationRepo.getAll(query);
    if (response.success || response.status === 200) {
      dispatch(actions.getAllSuccess(response));
      return {
        data: response,
        // page: response.current_page - 1,
        // totalCount: response.total,
      };
    } else {
      throw Error(
        response.errMgs ? response.errMgs : "Get All conversations Failed"
      );
    }
  } catch (error) {
   
    dispatch(actions.getAllFailed(error.message));
    return error;
  }
};
const getBookingDetails = (query) => async (dispatch) => {
  dispatch(actions.getBookingDetails());
  try {
    const response = await ConversationRepo.getBookingDetails(query);
    if (response.success || response.status === 200) {
      dispatch(actions.getBookingDetailsSuccess(response));
      return {
        data: response,
        // page: response.current_page - 1,
        // totalCount: response.total,
      };
    } else {
      throw Error(
        response.errMgs ? response.errMgs : "Get Booking details Failed"
      );
    }
  } catch (error) {
   
    dispatch(actions.getBookingDetailsFailed(error.message));
    return error;
  }
};

const getSingleConversations = (query) => async (dispatch) => {
  dispatch(actions.getSingleconversations());
  try {
    const response = await ConversationRepo.getSingle(query);
    if (response.success || response.status === 200) {
      dispatch(actions.getSingleconversationsSuccess(response));
      return response.data;
    } else {
      throw Error(
        response.errMgs ? response.errMgs : "Get Single conversation Failed"
      );
    }
  } catch (error) {
    
    dispatch(actions.getSingleconversationsFailed(error.message));
    return error;
  }
};

const createConversation = (query, formData) => async (dispatch) => {
  dispatch(actions.createConversation());
  try {
    const response = await ConversationRepo.createNewMessage(query, formData);
    if (response.success || response.status === 200) {
      dispatch(actions.createConversationSuccess(response));
      dispatch(
        enqueueSnackbar({
          message: "Message Saved succesfully.",
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "success",
          },
        })
      );
      return { ...response };
    } else {
      const e = new Error(response.message);
      e.response = response;
      throw e;
    }
  } catch (error) {
    dispatch(actions.createConversationFailed(error.message));
    dispatch(
      enqueueSnackbar({
        message: "Failed to Save Message.",
        options: {
          key: new Date().getTime() + Math.random(),
          variant: "error",
        },
      })
    );
    return error.response;
  }
};

export default {
  actions,
  reducer,
  selectors,
  getAll,
  getSingleConversations,
  createConversation,
  getBookingDetails,
};
