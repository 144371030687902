import { createSlice, createSelector } from "@reduxjs/toolkit";
import Auth from "../repositories/Auth";
import { updateToken } from "../api/SuperFetch";
import { enqueueSnackbar } from "./notifications";
const registerModuleSlice = createSlice({
  name: "registerModule",
  initialState: {
    loading: false,
    responding: false,
    error: null,
    data: [],
    success: "",
  },
  reducers: {
    registerCustomer: (state, action) => {
      state.loading = true;
      state.error = null;
      state.success = null;
    },
    registerCustomerSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.data = action.payload;
      state.success = "Registration Successful!";
    },
    registerCustomerFailed: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.success = null;
    },
  },
});

const { actions, reducer } = registerModuleSlice;

const createRegisterModule = (state) => state.registerModule.data;
const getIsLoading = (state) => state.registerModule.loading;

const selectors = {
  data: createSelector(createRegisterModule, (data) => data),
  isLoading: createSelector(getIsLoading, (data) => data),
};

const registerCustomer = (data) => async (dispatch) => {
  dispatch(actions.registerCustomer());
  try {
    const response = await Auth.register(data);
   
    if (response.success || response.status === 200) {
      dispatch(actions.registerCustomerSuccess(response));
      if (response.data && response.data.access_token) {
        updateToken(response);
      }
      
      
      return response;
    } else {
      let e = new Error(
        response.messsage ? response.message : "Sign Up Failed"
      );
      e.response = response;
      return e;
    }
  } catch (error) {
    dispatch(actions.registerCustomerFailed(error.message));
    dispatch(
      enqueueSnackbar({
        message: "Sign Up Failed",
        options: {
          key: new Date().getTime() + Math.random(),
          variant: "error",
        },
      })
    );
   
    return error.response;
  }
};

export default {
  actions,
  reducer,
  selectors,
  registerCustomer,
};
