import { baseUrl } from "./apiConfig";

export const getAuth = () => {
  if (typeof window !== "undefined") {
    const id = localStorage.getItem("travela_auth_id");
    const token = localStorage.getItem("travela_auth_token");
    const firebase_token = localStorage.getItem("travela_firebase_token");
    const user_phone = localStorage.getItem("user_phone_number");
    const user_profile = JSON.parse(localStorage.getItem("user_profile"));
    return {
      id,
      token,
      firebase_token,
      user_profile,
      user_phone,
    };
  }

  // const id = "6";
  // const token =
  //   "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC9hcGkudHJhdmVsYS53b3JsZFwvYXBpXC9hdXRoXC9sb2dpbiIsImlhdCI6MTY0MzAyNjA4NiwiZXhwIjoxNjc0NTYyMDg2LCJuYmYiOjE2NDMwMjYwODYsImp0aSI6Ik9PTHY0R1pxd3paUGY3T08iLCJzdWIiOjYsInBydiI6IjIzYmQ1Yzg5NDlmNjAwYWRiMzllNzAxYzQwMDg3MmRiN2E1OTc2ZjcifQ.W8EMOLvCjnfBhwm7ZyqLSkTOOXEAsShfiwmKZL647K0";
};

export const clearAuth = () => {
  if (typeof window !== "undefined") {
    localStorage.clear();
  }
  window.location.reload(false);
};

export default async (url, optionsProps = {}) => {
  const options = {
    method: "GET",
    mode: "cors",
    ...optionsProps,
    headers: new Headers({
      "Content-Type": "application/json",
      Accept: "application/json",
      ...optionsProps.headers,
    }),
  };

  try {
    const response = await fetch(`${baseUrl}/${url}`, options);
    if (response.status === 200) {
      return response.json();
    } else if (response.status === 401) {
      return response.json();
    } else {
      throw new Error(response.statusText);
    }
  } catch (error) {
    console.log("---AUTH ERROR---", error);
    return error;
  }
};
