import { createSelector, createSlice } from "@reduxjs/toolkit";
import { updateToken } from "../api/SuperFetch";
import Auth from "../repositories/Auth";
import { enqueueSnackbar } from "./notifications";
const loginModuleSlice = createSlice({
  name: "loginModule",
  initialState: {
    loading: false,
    responding: false,
    error: null,
    data: [],
    success: "",
  },
  reducers: {
    loginCustomer: (state, action) => {
      state.loading = true;
      state.error = "";
      state.success = "";
    },
    loginCustomerSuccess: (state, action) => {
      state.loading = false;
      state.error = "";
      state.loginStatus = true;
      state.data = action.payload;
      state.success = "Login Successful! Welcome home! :D";
    },
    loginCustomerFailed: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.success = "";
    },
    sendOtpCustomer: (state, action) => {
      state.loading = true;
      state.error = "";
      state.success = "";
    },
    sendOtpCustomerSuccess: (state, action) => {
      state.loading = false;
      state.error = "";
      state.loginStatus = true;
      state.data = action.payload;
      state.success = "Otp Send Successfully! :D";
    },
    sendOtpCustomerFailed: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.success = "";
    },
    verifyOtpCustomer: (state, action) => {
      state.loading = true;
      state.error = "";
      state.success = "";
    },
    verifyOtpCustomerSuccess: (state, action) => {
      state.loading = false;
      state.error = "";
      state.loginStatus = true;
      state.data = action.payload;
      state.success = "Otp Send Successfully! :D";
    },
    verifyOtpCustomerFailed: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.success = "";
    },
  },
});

const { actions, reducer } = loginModuleSlice;

const createloginModule = (state) => state.loginModule.data;
const getIsLoading = (state) => state.loginModule.loading;

const selectors = {
  data: createSelector(createloginModule, (data) => data),
  isLoading: createSelector(getIsLoading, (data) => data),
};

const loginCustomer = (values) => async (dispatch) => {
  dispatch(actions.loginCustomer());
  try {
    const response = await Auth.login(values);
    if (response.success || response.status === 200) {
      dispatch(actions.loginCustomerSuccess(response));
      if (response.data && response.data.access_token) {
        updateToken(response);
      }
      return response;
    } else {
      let e = new Error(
        response.messsage ? response.message : "Sign In Failed"
      );
      e.response = response;
      return e;
    }
  } catch (error) {
    dispatch(actions.loginCustomerFailed(error.message));
    dispatch(
      enqueueSnackbar({
        message: "Sign In Failed",
        options: {
          key: new Date().getTime() + Math.random(),
          variant: "error",
        },
      })
    );
    return error.response;
  }
};

const sendOtpCustomer = (values) => async (dispatch) => {
  dispatch(actions.sendOtpCustomer());
  try {
    const response = await Auth.sendOtp(values);
    if (response.success || response.status === 200) {
      dispatch(actions.sendOtpCustomerSuccess(response));
      return response;
    } else {
      let e = new Error(
        response.messsage ? response.message : "Send Otp Failed"
      );
      e.response = response;
      return e;
    }
  } catch (error) {
    dispatch(actions.sendOtpCustomerFailed(error.message));
    dispatch(
      enqueueSnackbar({
        message: "Send Otp Failed",
        options: {
          key: new Date().getTime() + Math.random(),
          variant: "error",
        },
      })
    );
    return error.response;
  }
};
const verifyOtpCustomer = (values) => async (dispatch) => {
  dispatch(actions.verifyOtpCustomer());
  try {
    const response = await Auth.verifyOtp(values);
    if (response.success || response.status === 200) {
      dispatch(actions.verifyOtpCustomerSuccess(response));
      return response;
    } else {
      let e = new Error(
        response.messsage ? response.message : "Verify Otp Failed"
      );
      e.response = response;
      return e;
    }
  } catch (error) {
    dispatch(actions.verifyOtpCustomerFailed(error.message));
    dispatch(
      enqueueSnackbar({
        message: "Verify Otp Failed",
        options: {
          key: new Date().getTime() + Math.random(),
          variant: "error",
        },
      })
    );
    return error.response;
  }
};

export default {
  actions,
  reducer,
  selectors,
  loginCustomer,
  sendOtpCustomer,
  verifyOtpCustomer,
};
