import querparser from "query-string";
import SuperFetch, { SuperUploader } from "../api/SuperFetch";

class MaplocationRepo {
  getAll(query) {
    return SuperFetch(`/popular-locations?${querparser.stringify(query)}`);
  }
  getPopularLocation() {
    return SuperFetch(`/popular-locations`);
  }
  getLatLng(placeid) {
    return SuperFetch(`/place/details/json`, {
      method: "POST",
      data: { placeid: placeid },
    });
  }
}

export default new MaplocationRepo();
