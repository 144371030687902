import { createSlice, createSelector } from "@reduxjs/toolkit";
import BookingRepo from "../repositories/BookingRepo";
import { enqueueSnackbar } from "./notifications";
import MaplocationRepo from "../repositories/MaplocationRepo";

const mapLocationModuleSlice = createSlice({
  name: "mapLocationModule",
  initialState: {
    loading: false,
    responding: false,
    error: null,
    data: [],
    single: {},
    singleLoading: false,
  },
  reducers: {
    getAll: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    getAllSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.data = action.payload.data;
    },
    getAllFailed: (state, action) => {
      state.loading = false;
      state.error = null;
    },
  },
});

const { actions, reducer } = mapLocationModuleSlice;

const getLocationModule = (state) => state.mapLocationModule.data;
const getIsLoading = (state) => state.mapLocationModule.loading;

const selectors = {
  data: createSelector(getLocationModule, (data) => data),
  isLoading: createSelector(getIsLoading, (data) => data),
};

const getAll = (query) => async (dispatch) => {
  dispatch(actions.getAll());
  try {
    const response = await MaplocationRepo.getAll(query);
    if (response.success || response.status === 200) {
      dispatch(actions.getAllSuccess(response));
      return {
        data: response.data,
        satus: response.status,
      };
    } else {
      throw Error(
        response.errMgs ? response.errMgs : "Get All MapLocation list Failed"
      );
    }
  } catch (error) {
  
    dispatch(actions.getAllFailed(error.message));
    return error;
  }
};

export default { actions, reducer, selectors, getAll };
