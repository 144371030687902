import querparser from "query-string";
import SuperFetch, { SuperUploader } from "../api/SuperFetch";

class ConversationRepo {
  getAll(query) {
    return SuperFetch(
      `conversation/${query.id}/messages?${querparser.stringify(query)}`
    );
  }

  getSingle(id) {
    return SuperFetch(`conversation/${id}`);
  }

  createNewMessage(id, message) {
    const data = { conversation_id: id, body: message };
    return SuperFetch(`message`, {
      method: "POST",
      data: data,
    });
  }

  getBookingDetails(query) {
   
    return SuperFetch(`/booking?${querparser.stringify(query)}`);
  }
}

export default new ConversationRepo();
