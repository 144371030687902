import querparser from "query-string";
import SuperFetch from "../api/SuperFetch";

class ProfileRepo {
  updateProfile(formData) {
   
    return SuperFetch(`/auth/profile`, {
      method: "POST",
      data: formData,
    });
  }
}

export default new ProfileRepo();
