import { CssBaseline, IconButton, useMediaQuery } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import theme from "../theme/theme";
import "../styles/globals.css";
import "../styles/style.scss";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import React from "react";
import configureAppStore from "../store/configureStore";
import BaseApi from "/src/api/BaseApi";
import App from "next/app";
import { QueryClient, QueryClientProvider } from "react-query";
import { getAuth } from "../src/api/auth";
import { SnackbarProvider } from "notistack";
import { Close, Router } from "@mui/icons-material";
import { useRouter } from "next/router";
import Loader from "../src/components/Shared/Loader";
import Head from "next/head";
const queryClient = new QueryClient();
const { store, persistor } = configureAppStore();

class MyApp extends App {
  componentDidMount() {
    const jssStyles = document.querySelector("#jss-server-side");
    if (jssStyles) {
      jssStyles.parentNode.removeChild(jssStyles);
    }
    if (typeof window !== "undefined") {
      const loader = document.getElementById("globalLoader");
      if (loader) loader.style.display = "none";
      // if (window.screen.width === 1280 && window.screen.height === 720) {
      //   document.body.style.zoom = "75%";
      // }
    }
    const { id, token } = getAuth();
    if (id && token) {
      BaseApi.init(id, token);
    }
  }

  render() {
    const { Component, pageProps } = this.props;
    const notistackRef = React.createRef();
    const onClickDismiss = (key) => () => {
      notistackRef.current.closeSnackbar(key);
    };

    return (
      <>
        <Head>
          <style>{Loader}</style>
        </Head>
        <QueryClientProvider client={queryClient}>
          <SnackbarProvider
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            ref={notistackRef}
            action={(key) => (
              <IconButton onClick={onClickDismiss(key)}>
                <Close />
              </IconButton>
            )}
          >
            <Provider store={store}>
              <PersistGate loading={null} persistor={persistor}>
                <ThemeProvider theme={theme}>
                  <CssBaseline />
                  <Component {...pageProps} />
                </ThemeProvider>
              </PersistGate>
            </Provider>
          </SnackbarProvider>
        </QueryClientProvider>
        <div id={"globalLoader"}>
          <div className="loader">
            <div>
              <img src="/images/logo.svg" height="150px" width="150px" alt="" />
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default MyApp;
