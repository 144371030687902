import querparser from "query-string";
import SuperFetch from "../api/SuperFetch";

class HotelRepo {
  getAll(query = { limit: 20, page: 1 }) {
    return SuperFetch(`/listing?${querparser.stringify(query)}`);
  }
  getSingle(id) {
    return SuperFetch(`/listing/${id}`);
  }
}

export default new HotelRepo();
