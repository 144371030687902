import SuperFetch from "../api/SuperFetch";

const Auth = {
  login: (values) => {
    return SuperFetch("auth/login", {
      method: "POST",
      data: values,
    });
  },
  register: (values) => {
    return SuperFetch("auth/register", {
      method: "POST",
      data: values,
    });
  },
  profile: () => {
    return SuperFetch("auth/profile");
  },
  sendOtp: (values) => {
    return SuperFetch("otp/send", {
      method: "POST",
      data: values,
    });
  },
  verifyOtp: (values) => {
    return SuperFetch("otp/verify", {
      method: "POST",
      data: values,
    });
  },
};

export default Auth;
