import querparser from "query-string";
import SuperFetch from "../api/SuperFetch";

class PaymentRepo {
  getSingle(id, status, query) {
   
    return SuperFetch(
      `/booking/${id}/payment?url=${status}&${querparser.stringify(query)}`
    );
  }
}

export default new PaymentRepo();
