import { createSlice, createSelector } from "@reduxjs/toolkit";
import HotelRepo from "../repositories/HotelRepo";
import Messages from "../repositories/Messages";
import { enqueueSnackbar } from "./notifications";

// import { enqueueSnackbar } from "./notifications";

const hotelModuleSlice = createSlice({
  name: "hotelModule",
  initialState: {
    loading: false,
    responding: false,
    error: null,
    data: [],
    single: {},
    singleLoading: false,
  },
  reducers: {
    getAll: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    getAllSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.data = action.payload.data;
    },
    getAllFailed: (state, action) => {
      state.loading = false;
      state.error = null;
    },

    // Get Customer Details
    getSingle: (state, action) => {
      state.singleLoading = true;
      state.error = null;
    },
    getSingleSuccess: (state, action) => {
      state.singleLoading = false;
      state.error = null;
      state.single = action.payload.data;
    },
    getSingleFailed: (state, action) => {
      state.singleLoading = false;
      state.error = true;
    },
  },
});

const { actions, reducer } = hotelModuleSlice;

const gethotelModule = (state) => state.hotelModule.data;
const getIsLoading = (state) => state.hotelModule.loading;
const getSingleLoading = (state) => state.hotelModule.singleLoading;
const singleHotel = (state) => state.hotelModule.single;

const selectors = {
  data: createSelector(gethotelModule, (data) => data),
  isLoading: createSelector(getIsLoading, (data) => data),
  single: createSelector(singleHotel, (data) => data),
  singleLoading: createSelector(getSingleLoading, (data) => data),
};

const getAll = (query, formData) => async (dispatch) => {
  dispatch(actions.getAll());
  try {
    const response = await HotelRepo.getAll(query, formData);
    if (response.success || response.status === 200) {
      dispatch(actions.getAllSuccess(response));
      return {
        data: response,
        // page: response.current_page - 1,
        // totalCount: response.total,
      };
    } else {
      throw Error(response.errMgs ? response.errMgs : "Get All Hotel Failed");
    }
  } catch (error) {
    
    dispatch(actions.getAllFailed(error.message));
    return error;
  }
};
const getSingle = (query) => async (dispatch) => {
  dispatch(actions.getSingle());
  try {
    const response = await HotelRepo.getSingle(query);
    if (response.success || response.status === 200) {
      dispatch(actions.getSingleSuccess(response));
      return response.data;
    } else {
      throw Error(
        response.errMgs ? response.errMgs : "Get Hotel Details Failed"
      );
    }
  } catch (error) {
   
    dispatch(actions.getSingleFailed(error.message));
    dispatch(
      enqueueSnackbar({
        message: "Cannot get Hotel Details.",
        options: {
          key: new Date().getTime() + Math.random(),
          variant: "error",
        },
      })
    );
    return error;
  }
};

export default { actions, reducer, selectors, getAll, getSingle };
