import { createSelector, createSlice } from "@reduxjs/toolkit";
import { updateToken } from "../api/SuperFetch";

import Auth from "../repositories/Auth";
import { enqueueSnackbar } from "./notifications";

const authSlice = createSlice({
  name: "auth",
  initialState: {
    loading: false,
    profileDataStatus: false,
    data: [],
    error: "",
    success: "",
  },
  reducers: {
    changePassword: (state, action) => {
      state.loading = true;
    },
    changePasswordSuccess: (state, action) => {
      state.loading = false;
      state.error = "";
    },
    changePasswordFailed: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.success = "";
    },

    swithAccount: (state, action) => {
      state.loading = false;
      state.error = "";
      state.success = "Account switched!";
      state.accounts = state.accounts.filter(
        (acc) => acc.id !== action.payload.id
      );
      state.accounts.push(state.data);
      state.data = action.payload;
    },

    removeAccount: (state, action) => {
      state.accounts = state.accounts.filter(
        (acc) => acc.id !== action.payload
      );
      state.success = "Account removed successfully!";
    },

    logout: (state, action) => {
      state.loginStatus = false;
      state.registerCustomerStatus = false;
      state.data = {};
      state.accounts = [];
      state.error = "";
      state.success = "";
    },

    profileData: (state, action) => {
      state.loading = true;
      state.error = "";
      state.success = "";
    },
    profileDataSuccess: (state, action) => {
      state.loading = false;
      state.error = "";
      state.profileDataStatus = true;
      state.data = action.payload;
      state.success = "Get Profile Data Successfull ";
      // state.permissionList = action.payload.permissions;
    },
    profileDataFailed: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.success = "";
    },
  },
});

// Extract the action creators object and the reducer
export const { actions, reducer } = authSlice;

export const selectors = {
  getAuth: createSelector(
    (state) => state.auth,
    (data) => data
  ),
};

export const profileData = () => async (dispatch) => {
  dispatch(actions.profileData());
  try {
    const response = await Auth.profile();
    if (response.success || response.status === 200) {
      dispatch(actions.profileDataSuccess(response));
      if (response.data) {
        localStorage.setItem("user_profile", JSON.stringify(response.data));
      }
    } else {
      let e = new Error(
        response.messsage ? response.message : "Get Profile Data Failed"
      );
      e.response = response;
      return e;
    }
  } catch (error) {
    dispatch(actions.profileDataFailed(error.message));
    dispatch(
      enqueueSnackbar({
        message: "Get Profile Data Failed",
        options: {
          key: new Date().getTime() + Math.random(),
          variant: "error",
        },
      })
    );
    return error.response;
  }
};

// export const swithAccount = (payload) => (dispatch) => {
//   localStorage.setItem("travela_auth_id", payload.id);
//   localStorage.setItem("travela_auth_token", payload.token);
//   dispatch(actions.swithAccount(payload));
// };

export default reducer;
