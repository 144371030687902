import { createSlice, createSelector } from "@reduxjs/toolkit";
import BookingRepo from "../repositories/BookingRepo";
import PaymentRepo from "../repositories/PaymentRepo";
import { enqueueSnackbar } from "./notifications";

const paymentModuleSlice = createSlice({
  name: "paymentModule",
  initialState: {
    loading: false,
    responding: false,
    error: null,
    data: [],
    single: {},
    singleLoading: false,
    amount: {},
  },
  reducers: {
    // Get Customer Details
    getSingle: (state, action) => {
      state.singleLoading = true;
      state.error = null;
    },
    getSingleSuccess: (state, action) => {
      state.singleLoading = false;
      state.error = null;
      state.single = action.payload.data;
    },
    getSingleFailed: (state, action) => {
      state.singleLoading = false;
      state.error = true;
    },

    bookingHotel: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    bookingHotelSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.data = action.payload.data;
    },
    bookingHotelFailed: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

const { actions, reducer } = paymentModuleSlice;

const getpaymentModule = (state) => state.paymentModule.data;
const getIsLoading = (state) => state.paymentModule.loading;
const getSingleLoading = (state) => state.paymentModule.singleLoading;
const singleBooking = (state) => state.paymentModule.single;

const selectors = {
  single: createSelector(singleBooking, (data) => data),
  singleLoading: createSelector(getSingleLoading, (data) => data),
};

const getSingle = (id, status, query) => async (dispatch) => {
  dispatch(actions.getSingle());
  try {
    const response = await PaymentRepo.getSingle(id, status, query);
    if (response.success || response.status === 200) {
      dispatch(actions.getSingleSuccess(response));
      return response;
    } else {
      throw Error(
        response.errMgs ? response.errMgs : "Get Booking Details Failed"
      );
    }
  } catch (error) {
    
    dispatch(actions.getSingleFailed(error.message));
    dispatch(
      enqueueSnackbar({
        message: "Cannot Get Booking Details.",
        options: {
          key: new Date().getTime() + Math.random(),
          variant: "error",
        },
      })
    );
    return error;
  }
};

export default { actions, reducer, selectors, getSingle };
