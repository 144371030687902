import { createSlice, createSelector } from "@reduxjs/toolkit";
import BookingRepo from "../repositories/BookingRepo";
import { enqueueSnackbar } from "./notifications";
import ProfileRepo from "../repositories/ProfileRepo";

const profileModuleSlice = createSlice({
  name: "profileModule",
  initialState: {
    loading: false,
    responding: false,
    error: null,
    data: [],
    single: {},
    singleLoading: false,
  },
  reducers: {
    updateProfile: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    updateProfileSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.data = action.payload.data;
    },
    updateProfileFailed: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

const { actions, reducer } = profileModuleSlice;

const getProfileModule = (state) => state.profileModule.data;
const getIsLoading = (state) => state.profileModule.loading;

const selectors = {
  data: createSelector(getProfileModule, (data) => data),
  isLoading: createSelector(getIsLoading, (data) => data),
};

const updateProfile = (formData) => async (dispatch) => {
  dispatch(actions.updateProfile());
  try {
    const response = await ProfileRepo.updateProfile(formData);
    if (response.success || response.status === 200) {
      dispatch(actions.updateProfileSuccess(response));
      return {
        ...response,
      };
    } else {
      throw Error(response.errMgs ? response.errMgs : "Profile Update Failed");
    }
  } catch (error) {
    
    dispatch(actions.updateProfileFailed(error.message));
    dispatch(
      enqueueSnackbar({
        message: "Profile Update Failed",
        options: {
          key: new Date().getTime() + Math.random(),
          variant: "error",
        },
      })
    );
    return error;
  }
};

export default { actions, reducer, selectors, updateProfile };
