import { combineReducers } from "redux";
import conversations from "../src/modules/conversations";
import messages from "../src/modules/messagesModule";
import notifications from "../src/modules/notifications";
import auth from "../src/modules/auth";
import registerModule from "../src/modules/registerModule";
import loginModule from "../src/modules/loginModule";
import hotelModule from "../src/modules/hotelModule";
import bookingModule from "../src/modules/bookingModule";
import reviewModule from "../src/modules/reviewModule";
import paymentModule from "../src/modules/paymentModule";
import profileModule from "../src/modules/profileModule";
import notificationModule from "../src/modules/notificationModule";
import mapLocationModule from "../src/modules/mapLocationModule";

export default combineReducers({
  auth,
  messages: messages.reducer,
  conversations: conversations.reducer,
  notifications: notifications.reducer,
  registerModule: registerModule.reducer,
  loginModule: loginModule.reducer,
  hotelModule: hotelModule.reducer,
  bookingModule: bookingModule.reducer,
  reviewModule: reviewModule.reducer,
  paymentModule: paymentModule.reducer,
  profileModule: profileModule.reducer,
  notificationModule: notificationModule.reducer,
  mapLocationModule: mapLocationModule.reducer,
});
