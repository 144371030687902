import querparser from "query-string";
import SuperFetch from "../api/SuperFetch";

class BookingRepo {
  getAll(query = { guest: null, limit: 20, page: 1 }) {
    return SuperFetch(`/booking?${querparser.stringify(query)}`);
  }
  getSingle(id, guestId) {
    return SuperFetch(`/booking/${id}?guest=${guestId}`);
  }

  booking(values) {
    return SuperFetch(`/booking`, {
      method: "POST",
      data: values,
    });
  }
}

export default new BookingRepo();
