import { createSlice, createSelector } from "@reduxjs/toolkit";
import Messages from "../repositories/Messages";

// import { enqueueSnackbar } from "./notifications";

const messagesModuleSlice = createSlice({
  name: "messagesModule",
  initialState: {
    loading: false,
    responding: false,
    error: null,
    data: [],
    single: {},
    findconvo: {},
  },
  reducers: {
    getAll: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    getAllSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.data = action.payload.data;
    },
    getAllFailed: (state, action) => {
      state.loading = false;
      state.error = null;
    },

    // Get Customer Details
    getSingle: (state, action) => {
      state.detailLoading = true;
      state.error = null;
    },
    getSingleSuccess: (state, action) => {
      state.detailLoading = false;
      state.error = null;
      state.single = action.payload.data;
    },
    getSingleFailed: (state, action) => {
      state.detailLoading = false;
      state.error = true;
    },
    // Get Customer Details
    findConversation: (state, action) => {
      state.detailLoading = true;
      state.error = null;
    },
    findConversationSuccess: (state, action) => {
      state.detailLoading = false;
      state.error = null;
      state.findconvo = action.payload.data;
    },
    findConversationFailed: (state, action) => {
      state.detailLoading = false;
      state.error = true;
    },
  },
});

const { actions, reducer } = messagesModuleSlice;

const getmessagesModule = (state) => state.messagesModule.data;
const getIsLoading = (state) => state.messagesModule.loading;
const getSingleLoading = (state) => state.messagesModule.singleLoading;
const singlemessagesModule = (state) => state.messagesModule.single;

const selectors = {
  data: createSelector(getmessagesModule, (data) => data),
  isLoading: createSelector(getIsLoading, (data) => data),
  single: createSelector(singlemessagesModule, (data) => data),
  singleLoading: createSelector(getSingleLoading, (data) => data),
};

const getAll = (query) => async (dispatch) => {
  dispatch(actions.getAll());
  try {
    const response = await Messages.getAll(query);
    if (response.success || response.status === 200) {
      dispatch(actions.getAllSuccess(response));
      return {
        data: response,
        // page: response.current_page - 1,
        // totalCount: response.total,
      };
    } else {
      throw Error(
        response.errMgs ? response.errMgs : "Get All messagesModule Failed"
      );
    }
  } catch (error) {
    
    dispatch(actions.getAllFailed(error.message));
    return error;
  }
};

const findConversation = (query) => async (dispatch) => {
  dispatch(actions.findConversation());
  try {
    const response = await Messages.findConversation(query);
    if (response.success || response.status === 200) {
      dispatch(actions.findConversationSuccess(response));
      return {
        data: response.data,
        success: response.success,
        message: response.message,
        // page: response.current_page - 1,
        // totalCount: response.total,
      };
    } else {
      throw Error(
        response.errMgs ? response.errMgs : "Find Conversation failed"
      );
    }
  } catch (error) {
    
    dispatch(actions.findConversationFailed(error.message));
    return error;
  }
};

// const getSingle = (query) => async (dispatch) => {
//   dispatch(actions.getSingle());
//   try {
//     const response = await Messages.getSingleMessage(query);
//     if (response.success || response.status === 200) {
//       dispatch(actions.getSingleSuccess(response));
//       return response.data;
//     } else {
//       throw Error(
//         response.errMgs
//           ? response.errMgs
//           : "Get Single Chat messagesModule details Failed"
//       );
//     }
//   } catch (error) {
//     
//     dispatch(actions.getSingleFailed(error.message));
//     // dispatch(
//     //   enqueueSnackbar({
//     //     message: "Cannot get Single Chat messagesModule details.",
//     //     options: {
//     //       key: new Date().getTime() + Math.random(),
//     //       variant: "error",
//     //     },
//     //   })
//     // );
//     return error;
//   }
// };

export default { actions, reducer, selectors, getAll, findConversation };
