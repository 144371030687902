import { createSlice, createSelector } from "@reduxjs/toolkit";
import BookingRepo from "../repositories/BookingRepo";
import { enqueueSnackbar } from "./notifications";

const bookingModuleSlice = createSlice({
  name: "bookingModule",
  initialState: {
    loading: false,
    responding: false,
    error: null,
    data: [],
    single: {},
    singleLoading: false,
  },
  reducers: {
    getAll: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    getAllSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.data = action.payload.data;
    },
    getAllFailed: (state, action) => {
      state.loading = false;
      state.error = null;
    },

    // Get Customer Details
    getSingle: (state, action) => {
      state.singleLoading = true;
      state.error = null;
    },
    getSingleSuccess: (state, action) => {
      state.singleLoading = false;
      state.error = null;
      state.single = action.payload.data;
    },
    getSingleFailed: (state, action) => {
      state.singleLoading = false;
      state.error = true;
    },

    bookingHotel: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    bookingHotelSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.data = action.payload.data;
    },
    bookingHotelFailed: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

const { actions, reducer } = bookingModuleSlice;

const getbookingModule = (state) => state.bookingModule.data;
const getIsLoading = (state) => state.bookingModule.loading;
const getSingleLoading = (state) => state.bookingModule.singleLoading;
const singleBooking = (state) => state.bookingModule.single;

const selectors = {
  data: createSelector(getbookingModule, (data) => data),
  isLoading: createSelector(getIsLoading, (data) => data),
  single: createSelector(singleBooking, (data) => data),
  singleLoading: createSelector(getSingleLoading, (data) => data),
};

const getAll = (query) => async (dispatch) => {
  dispatch(actions.getAll());
  try {
    const response = await BookingRepo.getAll(query);
   
    if (response.success || response.status === 200) {
      dispatch(actions.getAllSuccess(response));
      return {
        data: response,
        // page: response.current_page - 1,
        // totalCount: response.total,
      };
    } else {
      throw Error(
        response.errMgs ? response.errMgs : "Get All Booking list Failed"
      );
    }
  } catch (error) {
   
    dispatch(actions.getAllFailed(error.message));
    return error;
  }
};

const getSingle = (id, query) => async (dispatch) => {
  dispatch(actions.getSingle());
  try {
    const response = await BookingRepo.getSingle(id, query);
    if (response.success || response.status === 200) {
      dispatch(actions.getSingleSuccess(response));
      return response.data;
    } else {
      throw Error(
        response.errMgs ? response.errMgs : "Get Booking Details Failed"
      );
    }
  } catch (error) {
  
    dispatch(actions.getSingleFailed(error.message));
    dispatch(
      enqueueSnackbar({
        message: "Cannot Get Booking Details.",
        options: {
          key: new Date().getTime() + Math.random(),
          variant: "error",
        },
      })
    );
    return error;
  }
};

const bookingHotel = (query, formData) => async (dispatch) => {
  dispatch(actions.bookingHotel());
  try {
    const response = await BookingRepo.booking(query, formData);
    if (response.success || response.status === 200) {
      dispatch(actions.bookingHotelSuccess(response));
      return {
        ...response,
      };
    } else {
      throw Error(response.errMgs ? response.errMgs : "Booking Request Failed");
    }
  } catch (error) {
    
    dispatch(actions.bookingHotelFailed(error.message));
    dispatch(
      enqueueSnackbar({
        message: "Booking Request Failed",
        options: {
          key: new Date().getTime() + Math.random(),
          variant: "error",
        },
      })
    );
    return error;
  }
};

export default { actions, reducer, selectors, getAll, getSingle, bookingHotel };
