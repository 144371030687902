import { createSlice, createSelector } from "@reduxjs/toolkit";
import BookingRepo from "../repositories/BookingRepo";
import reviewRepo from "../repositories/reviewRepo";
import { enqueueSnackbar } from "./notifications";

const reviewModuleSlice = createSlice({
  name: "reviewModule",
  initialState: {
    loading: false,
    responding: false,
    error: null,
    data: [],
    single: {},
    singleLoading: false,
  },
  reducers: {
    getAll: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    getAllSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.data = action.payload.data;
    },
    getAllFailed: (state, action) => {
      state.loading = false;
      state.error = null;
    },

    getSingle: (state, action) => {
      state.singleLoading = true;
      state.error = null;
    },
    getSingleSuccess: (state, action) => {
      state.singleLoading = false;
      state.error = null;
      state.single = action.payload.data;
    },
    getSingleFailed: (state, action) => {
      state.singleLoading = false;
      state.error = null;
    },

    createReview: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    createReviewSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.data = action.payload.data;
    },
    createReviewFailed: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

const { actions, reducer } = reviewModuleSlice;

const getreviewModule = (state) => state.reviewModule.data;
const getIsLoading = (state) => state.reviewModule.loading;
const getSingleLoading = (state) => state.reviewModule.singleLoading;
const singleBooking = (state) => state.reviewModule.single;

const selectors = {
  data: createSelector(getreviewModule, (data) => data),
  isLoading: createSelector(getIsLoading, (data) => data),
  single: createSelector(singleBooking, (data) => data),
  singleLoading: createSelector(getSingleLoading, (data) => data),
};

const getAll = (id, query) => async (dispatch) => {
  dispatch(actions.getAll());
  try {
    const response = await reviewRepo.getAll(id, query);
    if (response.success || response.status === 200) {
      dispatch(actions.getAllSuccess(response));

      return {
        data: response,
        page: response.current_page - 1,
        totalCount: response.total,
      };
    } else {
      throw Error(response.errMgs ? response.errMgs : "Get All Review Failed");
    }
  } catch (error) {
    
    dispatch(actions.getAllFailed(error.message));
    dispatch(
      enqueueSnackbar({
        message: "Cannot Get AllReview.",
        options: {
          key: new Date().getTime() + Math.random(),
          variant: "error",
        },
      })
    );
    return error;
  }
};

const createReview = (query, formData) => async (dispatch) => {
  dispatch(actions.createReview());
  try {
    const response = await reviewRepo.createReview(query, formData);
    if (response.success || response.status === 200) {
      dispatch(actions.createReviewSuccess(response));
      return {
        ...response,
      };
    } else {
      throw Error(
        response.errMgs ? response.errMgs : "Create Review Request Failed"
      );
    }
  } catch (error) {
    
    dispatch(actions.createReviewFailed(error.message));
    dispatch(
      enqueueSnackbar({
        message: "Create Review Request Failed",
        options: {
          key: new Date().getTime() + Math.random(),
          variant: "error",
        },
      })
    );
    return error;
  }
};

export default { actions, reducer, selectors, getAll, createReview };
