import querparser from "query-string";
import SuperFetch from "../api/SuperFetch";

class Messages {
  getAll(query = { guest: null, limit: 20, page: 1 }) {
    return SuperFetch(`/conversation?${querparser.stringify(query)}`);
  }

  findConversation(query = { host: null, guest: null, limit: 20, page: 1 }) {
    return SuperFetch(`/conversation/find?${querparser.stringify(query)}`);
  }
}

export default new Messages();
